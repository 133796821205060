<ng-template #compatibleStringTemplate let-context="context" let-viewContext="viewContext">
  <!-- Compatible with Chinese input in Chrome with \n -->
  <span editable-text data-slate-string="true"
    >{{ context.text }}<span data-slate-zero-width>{{ '\uFEFF' }}</span></span
  >
</ng-template>
<ng-template #voidStringTemplate let-context="context" let-viewContext="viewContext">
  <span editable-text data-slate-zero-width="z" attr.data-slate-length="{{ context.elementStringLength }}">{{ '\uFEFF' }}</span>
</ng-template>
<ng-template #emptyTextTemplate let-context="context" let-viewContext="viewContext">
  <span editable-text data-slate-zero-width="z" data-slate-length="0">{{ '\uFEFF' }}</span>
</ng-template>
