<div contenteditable="false">
  <div style="padding: 75% 0 0 0; position: relative">
    <iframe [src]="url" [frameBorder]="0" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"></iframe>
  </div>

  <input
    [value]="element.url"
    (click)="$event.stopPropagation()"
    style="margin-top: 5px; box-sizing: border-box; width: 100%; margin-top: 1em"
    (change)="inputChange($event)"
  />
</div>
