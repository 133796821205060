<div class="demo-rich-editor-wrapper">
  <slate-editable
    class="demo-slate-angular-editor"
    [editor]="editor"
    [(ngModel)]="value"
    [renderElement]="renderElement"
    placeholder="Enter some text..."
  >
  </slate-editable>
</div>
