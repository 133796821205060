export const TOP_BLUR = 'blur';
export const TOP_COMPOSITION_END = 'compositionend';
export const TOP_COMPOSITION_START = 'compositionstart';
export const TOP_COMPOSITION_UPDATE = 'compositionupdate';
export const TOP_KEY_DOWN = 'keydown';
export const TOP_KEY_PRESS = 'keypress';
export const TOP_KEY_UP = 'keyup';
export const TOP_MOUSE_DOWN = 'mousedown';
export const TOP_MOUSE_MOVE = 'mousemove';
export const TOP_MOUSE_OUT = 'mouseout';
export const TOP_TEXT_INPUT = 'textInput';
export const TOP_PASTE = 'paste';
