<div class="demo-rich-editor-wrapper single">
  <div class="demo-global-toolbar">
    <demo-button (onMouseDown)="addImages()">
      <span class="material-icons">image</span>
    </demo-button>
  </div>
  <slate-editable
    class="demo-slate-angular-editor demo-image-editor"
    [editor]="editor"
    [(ngModel)]="value"
    (ngModelChange)="valueChange($event)"
    [renderElement]="renderElement()"
  >
  </slate-editable>
</div>
